exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-landing-jsx-content-file-path-opt-buildhome-repo-landing-institutions-mdx": () => import("./../../../src/templates/landing.jsx?__contentFilePath=/opt/buildhome/repo/landing/institutions.mdx" /* webpackChunkName: "component---src-templates-landing-jsx-content-file-path-opt-buildhome-repo-landing-institutions-mdx" */),
  "component---src-templates-landing-jsx-content-file-path-opt-buildhome-repo-landing-radiologists-mdx": () => import("./../../../src/templates/landing.jsx?__contentFilePath=/opt/buildhome/repo/landing/radiologists.mdx" /* webpackChunkName: "component---src-templates-landing-jsx-content-file-path-opt-buildhome-repo-landing-radiologists-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-cruise-lines-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/articles/cruise-lines.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-cruise-lines-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-dental-access-pathway-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/articles/dental-access-pathway.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-dental-access-pathway-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-gdpr-obligations-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/articles/GDPR-obligations.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-gdpr-obligations-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-imaging-centres-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/articles/imaging-centres.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-imaging-centres-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-why-request-images-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/articles/why-request-images.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-articles-why-request-images-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-carnival-seacare-interface-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/carnival-seacare-interface.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-carnival-seacare-interface-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-clinical-request-details-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/clinical-request-details.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-clinical-request-details-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-connecting-to-destinations-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/connecting-to-destinations.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-connecting-to-destinations-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-fulfill-requests-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/fulfill-requests.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-fulfill-requests-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-how-to-report-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/how-to-report.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-how-to-report-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-importing-images-to-pacs-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/importing-images-to-pacs.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-importing-images-to-pacs-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-making-a-request-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/making-a-request.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-making-a-request-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-receiving-images-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/receiving-images.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-receiving-images-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-setting-up-2-fa-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/setting-up-2fa.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-setting-up-2-fa-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-sharing-images-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/sharing-images.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-sharing-images-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-uploading-images-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/help/uploading-images.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-help-uploading-images-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-accessibility-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/legal/accessibility.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-accessibility-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-dpa-b-2-b-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/legal/dpa-b2b.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-dpa-b-2-b-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-privacy-policy-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/legal/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-privacy-policy-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-security-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/legal/security.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-security-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-tos-b-2-b-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/legal/tos-b2b.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-tos-b-2-b-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-tos-patients-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/legal/tos-patients.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-buildhome-repo-legal-tos-patients-mdx" */)
}

